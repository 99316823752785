/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Black.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Sailec', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F7F6;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.load{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);
  /*change these sizes to fit into your project*/
  width:100px;
  height:100px;
}
.cr-load hr{border:0;margin:0;width:40%;height:40%;position:absolute;border-radius:50%;animation:spin 2s ease infinite}

.cr-load :first-child{background:#5ECB9E;animation-delay:-1.5s}
.cr-load :nth-child(2){background:#029094;animation-delay:-1s}
.cr-load :nth-child(3){background:#5ECB9E;animation-delay:-0.5s}
.cr-load :last-child{background:#029094}

@keyframes spin{
  0%,100%{transform:translate(0)}
  25%{transform:translate(160%)}
  50%{transform:translate(160%, 160%)}
  75%{transform:translate(0, 160%)}
}

.question-enter {
  opacity: 0;
  transform: translate3d(0, 30%, 0);
}
.question-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 300ms, transform 300ms;
}
.question-exit {
  opacity: 1;
}
.question-exit-active {
  opacity: 0;
  transform: translate3d(0, -30%, 0);
  transition: opacity 300ms, transform 300ms;
}

.activeMenu > div {
  color: #fff;
  background: #029094;
  border-radius: 10px;
}

ul ul {
  list-style-type: none;
  padding-inline-start: 18px;
  padding-top: 5px;
  & > li {
    text-indent: -17px;
  }
  & > li:before {
    content: "\2014 ";
    margin-right: 5px;
  }
}

.reactour__helper {
  outline: none;
}

div.success-snack {
  background-color: #029094!important;
}

div.list-item, span.list-item {
  display: list-item;
  margin-left: 14px;
}

a {
  color: #43C7FF;
  text-decoration: none;
}

.no-overflow {
  overflow: hidden!important
}

.shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

iframe .ytp-expand-pause-overlay {
  display: none!important;
}

@media screen and (min-width: 1660px) {
  .terms {
    position: fixed;
  }
}
